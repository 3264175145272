import {App} from "vue"; 


// import dayjs from 'dayjs'
// 이미 전역으로 선언되었기에 따로 import 하지 않는다.
// ㄴ 오히려 하면 start-mpa 빌드시 용량이 커진다. (이미 있는데 또 로딩하므로)

import dayjs from 'dayjs' // 로딩 속도에 문제가 있는듯.. dayjs 가 global 이긴 하지만 여기다 안하면 오류남
import relativeTime from "dayjs/plugin/relativeTime" // https://day.js.org/docs/en/plugin/relative-time
import 'dayjs/locale/ko'
dayjs.extend(relativeTime);
dayjs.locale('ko') // dayjs 한국어 설정

// @ts-ignore
// window.ReactNativeWebView = window.ReactNativeWebView || {};

//------------------------------------------------------
// RN webview
//------------------------------------------------------
declare global {
  interface Window { ReactNativeWebView: any; }
}

axios.defaults.headers.common['Mode'] = 'emp';

export default {
  install(app: App) {
    app.config.globalProperties.T = T
    // app.config.globalProperties._ = Lodash  // (절대안됨) vue 3.x 에서는 instance 변수로 _ 를 막아둔 것 같다.
    app.config.globalProperties.lodash = _     // (잘됨) 그래서 instance 에서는 lodash 로 사용하자. 헷갈리면 둘다 lodash 로 쓰자
    app.config.globalProperties.dayjs = dayjs
    app.config.globalProperties.$axios = axios
    
  },
}

