/**
 * vue 전용
 */
import T from './index'

export default {

  /**
   * vue class 두개를 합친다. string map null 등등 고려함<pre>
   *   T.mergeClass("a b", {c:true, d:false})     === "a b c"
   *   T.mergeClass({a: true}, {b:true, c:false}) === {a: true, b:true, c:false}
   *   T.mergeClass({c:true, d:false}, "a b")     === "c a b"
   *   </pre>
   * @param {string|{}} class1
   * @param {string|{}} class2
   * @returns {string|{}}
   */
  mergeClass: (class1, class2) => {
    const type1 = typeof class1 === 'string' ? 'string' : _.isPlainObject(class1) ? 'map' : 'null'
    const type2 = typeof class2 === 'string' ? 'string' : _.isPlainObject(class2) ? 'map' : 'null'
    if (type2 === 'null') return type1 === 'null' ? "" : class1
    if (type1 === 'null') return class2
    switch(type1 + '_' + type2){
      case 'string_string': return class1 + ' ' + class2
      case 'string_map'   : return class1 + ' ' + classMapToString(class2)
      case 'map_string'   : return classMapToString(class1) + ' ' + class2
      case 'map_map'      : return {...class1, ...class2}
    }
    function classMapToString(map) {
      return Object.entries(map).filter(([,v])=>v).map(([k])=>k).join(" ")
    }
  },

  /**
   * vue 변수의 타입을 확인한다<pre>
   * 만약 Ref로 wrap 되어 있다면 unref 하여 평가한다.
   */
  isObjectOrArray: (value: any) : "array" | "object" | "undefined" | "null" | "number" | "string" | "unknown" => {
    if (value === undefined) return "undefined";
    if (value === null) return "null";
    
    const realValue = unref(value)
    if (Array.isArray(realValue)) return "array";
    const tv = typeof realValue
    if (tv === "object") return "object";
    if (tv === "number") return "number";
    if (tv === "string") return "string";
    return "unknown";
  },
  
  /**
   * vue 변수의 타입을 확인한다<pre>
   * 만약 Ref로 wrap 되어 있다면 unref 하여 반환한다.
   */
  unwrapVueObject: <V>(obj: V): V => {
    if (isProxy(obj)) return toRaw(obj)
    if (isRef(obj)) return unref<V>(obj)
    return obj
  }

}
