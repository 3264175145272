/**
 * 웹부라우져 전용 함수들
 */

import T from './index'
import {MapType} from "./tools_types";

export type QsConvertType = 'number' | 'comma_array' | 'comma_number_array' | 'string[]'
export type QsConvertMap  = {[key: string]: QsConvertType}

const convertDefineMap: {
  number            : (str: string) => number | null
  comma_array       : (str: string) => string[]
  comma_number_array: (str: string) => number[]
  // "string[]"        : (str: string) => string[]
} = {
  number: (str: string): number | null => T.toNumber(str),
  comma_array: (str: string): string[] => {
    if (T.isBlank(str)) return []
    if (T.contains(str, ',')) {
      const ar = str.trim().split(/\s*,\s*/)
      return ar.reduce<string[]>((t, p)=>{t.push(p);return t},[])
    } else {
      return [str.trim()]
    }
  },
  // "string[]": (str: string): string[] => {
  //   if (T.isBlank(str)) return []
  //   if (T.contains(str, ',')) {
  //     return str.trim().split(/\s*,\s*/)
  //   } else {
  //     return [str.trim()]
  //   }
  // },
  comma_number_array: (str: string): number[] => {
    if (T.isBlank(str)) return []
    if (T.contains(str, ',')) {
      const ar = str.trim().split(/\s*,\s*/)
      return ar.reduce<number[]>((t,p)=>{
        const cn = T.toNumber(p);
        if (cn !== null) t.push(cn);
        return t
      },[])
    } else {
      const cn = T.toNumber(str.trim());
      return cn === null ? [] : [cn]
    }
  },
}

// const toolsWeb: any = { 
// (경고) 위처럼 : any 로 했다가 T.함수명 인식 안되서 반나절 고생함... 겨우 찾았다..
const toolsWeb = {

  historyPush: (url: string, obj): void => {
    if (typeof history.pushState === "function")
      history.pushState(T.isNU(obj) ? {} : obj, '', url)
  },

  historyReplace: (url: string, obj ?: any): void => {
    if (typeof history.pushState === "function")
      history.replaceState(T.isNU(obj) ? {} : obj, '', url)
  },

  /** 현재 주소에서 QS만 바꾼다 */
  historyReplaceQS: (qsMapObj: MapType): void => {
    const currentUrl = new T.Urlmanager(location.href).replaceQuery(qsMapObj).toString()
    return toolsWeb.historyReplace(currentUrl)
  },

  onChangeURL: callbackFunc => typeof history.pushState === "function"
    && window.addEventListener('popstate', callbackFunc),

  toQueryString: (obj:MapType) =>
    !_.isPlainObject(obj) ? obj :
      Object.entries(obj).map(([k,v])=>encodeURIComponent(k)+'='+encodeURIComponent(v)).join('&'),

  // toQueryString: (obj:MapType) => {
  //   if (!_.isPlainObject(obj)) return obj
  //   const ret = Object.entries(obj)
  //     .map(([k, v]) => {
  //       T.logV({k, v, ttt: typeof(v)})
  //       let str = encodeURIComponent(k) + '='
  //       if (T.isNotEmptyArray(v)) {
  //         T.logV("배열이다", {vjoin : v.join(","), xxx: encodeURIComponent(v.join(","))})
  //         str += encodeURIComponent(v.join(","))
  //       }
  //       else str += encodeURIComponent(v)
  //       return str
  //     }).join('&')
  //   T.logV({obj, ret})
  //   return ret
  // },

  makeUrlQueryString: obj => {
    return Object.keys(obj)
      .map(v => `${v}=${encodeURIComponent(obj[v])}`)
      .join('&')
  },

  // queryStringToMap__old: (qs, optParseNumber = false) => {
  //   qs = qs || location.search.slice(1);
  //
  //   const pairs = qs.split("&");
  //   const result = {};
  //
  //   pairs.forEach(p => {
  //     const pair = p.split("=");
  //     const key = pair[0];
  //     if (T.isEmpty(key)) return
  //
  //     let value = decodeURIComponent(pair[1] || "");
  //
  //     if (optParseNumber && isNaN(value) === false) { // vue에서 숫자는 숫자로 확실히 변환해 주어야 오류가 나지 않음.
  //       value = value * 1
  //     }
  //
  //     if (result[key]) {
  //       if (Object.prototype.toString.call(result[key]) === "[object Array]") {
  //         result[key].push(value);
  //       } else {
  //         result[key] = [result[key], value];
  //       }
  //     } else {
  //       result[key] = value;
  //     }
  //   });
  //
  //   return JSON.parse(JSON.stringify(result))
  // },

  /**
   * queryString 을 map 으로 변환<pre>
   *   값의 타입변환이 필요한경우 지정가능
   *
   *   현재 사용 가능한 함수
   *     number              : 숫자로 변환
   *     comma_number_array  : 콤마숫자문장을 배열로 변환 2,5,6 -> [2,5,6]
   *     comma_array         : 콤마구분을 배열로 변환 aaa,bbb,ccc -> ['aaa','bbb','ccc']
   *
   *   const qs_map = queryStringToMap({
   *     no: 'number',
   *     s_class_list: 'comma_number_array',
   *   })</pre>
   * @param convOpt 키별 값 형태 지정
   * @returns 변환된 map
   */
  queryStringToMap: (convOpt: QsConvertMap = {}): MapType => {
    const qs = location.search.slice(1);
    return T.parseQueryString(qs, convOpt)
  },

  parseQueryString: (str?: string, convOpt: QsConvertMap = {}): MapType => {
    if (!str || T.isBlank(str)) return {}
    
    let qs = str
    const questionPos = str.indexOf('?');
    if (questionPos > -1) { // ? 가 있으면 그 뒤에만 작업
      qs = str.substring(questionPos + 1);
    } else if (str.startsWith("http")) { // ? 도 없는데 http 로 시작하는 경우는 qs가 아에 없는 경우
      return {}
    }
    
    const pairs = qs.split("&");
    const result = {};

    pairs.forEach(p => {
      const pair = p.split("=");
      const key = pair[0];
      if (T.isEmpty(key)) return

      let value: any = decodeURIComponent(pair[1] || "");

      // 값 타입 변환
      const fnName: QsConvertType = convOpt[key];
      if (fnName) { // 변환대상인 경우
        const convFN = convertDefineMap[fnName]
        if (!convFN) throw new Error('변환 함수가 없습니다. - 컬럼 = ' + key+  ', 함수명 = ' + fnName)
        value = convFN(value)
      }

      if (result[key] !== undefined) { // 이미 키가 있다면
        if (_.isArray(result[key])) { // 그게 배열이면
          result[key].push(value); // 뒤에 추가
        } else { // 아니라면 ( === 두번재 등록이라면 )
          result[key] = [result[key], value]; // 배열로 만든다.
        }
      } else {
        result[key] = value;
      }
    });

    return result
    
  },

  queryMapConvertType: (obj: MapType, convOpt: QsConvertMap = {}): MapType => {
    const ret = {}
    Object.entries(obj).forEach(([k, v]) => {
      const fnName: QsConvertType = convOpt[k];
      if (fnName) {
        const convFN = convertDefineMap[fnName]
        if (!convFN) throw new Error('변환 함수가 없습니다. - 컬럼 = ' + k+  ', 함수명 = ' + fnName)
        ret[k] = convFN(v)
      } else {
        ret[k] = v
      }
    })
    return ret;
  },
  

  /**
   * (비추) javascript 새창열기
   * @param url
   * @param name
   * @param width
   * @param height
   * @param focus 자동 포커스 여부 @default false
   * @returns {WindowProxy}
   */
  newWin: (url, name, width, height, focus:boolean = false) => {
    const popupWidth = width;
    const popupHeight = height;

    const winWidth  = document.body.clientWidth;  // 현재창의 너비
    const winHeight = document.body.clientHeight; // 현재창의 높이
    const winX      = window.screenX || window.screenLeft || 0;// 현재창의 x좌표
    const winY      = window.screenY || window.screenTop || 0; // 현재창의 y좌표
    const popupX = winX + (winWidth - popupWidth) / 2;
    const popupY = winY + (winHeight - popupHeight - 100) / 2;

    const inst = window.open(url, name, "toolbar=0, status=0, width=" + width + ", height=" + height + ', left=' + popupX + ', top=' + popupY);
    if (inst) {
      inst.focus()
      return inst
    } else {
      T.alert("팝업 차단을 해제하셔야 합니다.").then()
      return undefined
    }
  },

  /** 팝인경우 닫는다. vue template 용 */
  popupClose: () => self.close(),

  toForm: (obj:{[key:string]:any}) =>
    Object.entries(obj)
      .reduce((t, [k,v]) => { t.append(k, v); return t}, new FormData()),


  /**
   * url 을 추가 또는 수정한다.<pre>
   *
   *   - 객체생성 : new T.Urlmanager(location.href)
   *
   *   - qs 추가
   *     .add('aaa', 'bbb')   -- 일반적인 추가
   *     .add('aaa', [1,2,3]) -- 배열 변수 추가
   *
   *   - qs 수정 또는 추가
   *     .replace('aaa', 111)         -- 기존 qs값 수정 ( 없으면 추가 )
   *     .replace({aaa:111, bbb:222}) -- map 형식으로 일괄 수정 ( 없으면 추가 )
   *
   *   - qs만 전부 날리고 추가 : ( 도메인까지는 유지된다 )
   *     .replaceQuery({aaa:111, bbb:222}) -- 기존 qs는 싹다 날리고 새로 셋팅 ( map 형식 )
   *     .replaceQuery('aa=111&bb=222')    -- 기존 qs는 싹다 날리고 새로 셋팅 ( 문자열 )
   *
   *   - qs 삭제
   *     .remove('aaa', 'bbb')    -- qs 중에서 aaa, bbb 키 2개만 삭제한다.
   *     .remove(['aaa', 'bbb'])  -- 배열을 넣어도 된다.
   * </pre> */
  Urlmanager: class {
    private url: string = '';

    constructor(url) {
      this.url = url
    }

    /**
     * param 하나를 추가한다. value 는 배열도 가능하다<pre>
     *   .add('aaa', 'bbb')   -- 일반적인 추가
     *   .add('aaa', [1,2,3]) -- 배열 변수 추가. add=1&add=2&add=3
     * </pre> */
    add(name:string, value:any) {
      if (!!value == false) return this;
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) this.add(name, value[i]);
        return this;
      }
      this.url += this.url.indexOf("?") < 0 ? "?" : "&";
      this.url += name + "=" + encodeURIComponent(value);
      return this;
    }

    /**
     * 지정된 param 을 replace 한다. 없으면 추가한다.<pre>
     *   첫번째 변수에 object (map) 을 사용해도 된다.
     *     replace({aaa:111,bbb:222}) -- 이미 있으면 replace 없으면 add 된다.
     *     replace('aaa',333)         -- aaa가 있으면 333으로 바뀐다. 없으면 추가된다.
     * * </pre> */
    replace(name_or_object: string|MapType, value ?:any) {
      const obj = name_or_object;
      if (typeof obj === "object") {
        for (const k in obj) this.replace(k, obj[k]);
        return this;
      }
      this.remove(obj);
      this.add(obj, value);
      return this;
    }

    /**
     * 기존 qs 를 싹다 없애고 새로운 qs로 대체한다<pre>
     *   .replaceQuery({aaa:111, bbb:222}) -- 기존 qs는 싹다 날리고 새로 셋팅 ( map 형식 )
     *   .replaceQuery('aa=111&bb=222')    -- 기존 qs는 싹다 날리고 새로 셋팅 ( 문자열 )
     * </pre> */
    replaceQuery(str_or_obj: string|MapType) {
      this.url = this.url.replace(/\?.*/g,""); // 기존 query 제거
      if (_.isPlainObject(str_or_obj)) {
        this.url += '?' + T.toQueryString(str_or_obj as MapType);
        return this;
      }
      this.url += '?' + str_or_obj;
      return this;
    }

    /**
     * 특정 param 들을 제외한다
     *   .remove('aaa','bbb')
     *   .remove(['aaa','bbb'])
     * @param args
     */
    remove(...args: any[]) {
      if (T.isEmptyArray(args)) return this
      args.forEach(k => {
        if (typeof k === 'string')
          this.url = this.url.replace(new RegExp("([?&])" + k + "=[^&]*&?", "g"), "$1")
            .replace(/([?&])$/, ""); // https://wiki.imdgo.com/pages/viewpage.action?pageId=164300485
        if (T.isNotEmptyArray(k))
          (k as string[]).forEach(kk => this.remove(kk))
      })
      return this
    }


    // 최종결과
    toString() {
      return this.url.replace(/\?&+/g, "?").replace(/\?$/, "");
    }

  },

  urlPrefix(url) {
    if (T.isEmpty(url)) return ""
    if (url.startsWith("/")) return url
    if (url.startsWith("http")) return url
    return "https://" + url
  },

  /**
   * 지정된 element 의 최대 height 를 구한다.
   * @param {any} el el
   * @param {number} depth (기본:2) 내부 탐색단계
   * @returns {number} 최대 height
   */
  getDomInnerHeight(el, depth = 2) {
    if (T.isNU(el)) return 0 // 애초에 널이면 0
    if (T.isNU(el.children) && T.isNotNU(el.$el)) el = el.$el // children 은 없지만 대신 $el 이 있다면 그걸로 재시도
    if (T.isNU(el.children)) return 0 // 그래도 children 이 없으면 0

    let maxHeight = 0
    const _getClientHeight = (el, depth) => {
      for(const c of el.children) {
        if (maxHeight < c.clientHeight) maxHeight = c.clientHeight
        if (depth > 1 && c.children) _getClientHeight(c, depth - 1)
      }
      return 1;
    }

    _getClientHeight(el, depth);
    return maxHeight
  },

  /**
   * 지정된 dom 의 style를 조정한다<pre>
   *   T.setDomStyle(ref_list. 'height', '300px')
   * @param {any} el 대상
   * @param {string} key 키
   * @param {string} value 값
   * @returns {boolean} 설정 성공여부
   */
  setDomStyle(el, key, value) {
    if (T.isNU(el)) return false // 애초에 널이면 0
    if (T.isNU(el.style) && T.isNotNU(el.$el)) el = el.$el // children 은 없지만 대신 $el 이 있다면 그걸로 재시도
    if (T.isNU(el.style)) return false // 그래도 children 이 없으면 0
    el.style[key] = value
    return true
  },

  getCookie(name) {
    const nameOfCookie = name + "=";
    let x = 0;
    let endOfCookie
    while (x <= document.cookie.length) {
      const y = (x+nameOfCookie.length);
      if (document.cookie.substring(x, y) === nameOfCookie) {
        if ((endOfCookie=document.cookie.indexOf(";", y)) === -1)
          endOfCookie = document.cookie.length;
        return unescape(document.cookie.substring(y, endOfCookie));
      }
      x = document.cookie.indexOf(" ", x) + 1;
      if (x === 0)
        break;
    }
    return "";
  },

  setCookie(name, value, expiredays) {
    const todayDate = new Date();
    todayDate.setDate(todayDate.getDate() + expiredays);
    if(expiredays == 0)
      document.cookie = name + "=" + escape(value) + "; path=/;"
    else
      document.cookie = name + "=" + escape(value) + "; path=/; expires=" + todayDate.toUTCString() + ";"
  },

}

export default toolsWeb
