/**
 * 로그인 관련 기본 정의
 * 
 * 1. 타입정의 : 사용자 정보, 로그인 응답 정보, 로그인 폼 형식
 * 2. 로그인 관련 api 호출 : 로그인, 아웃, 세션갱신, 로그인상태 조회, 로그인 스위치
 */
import {EnvType, EnvTypeExt, LoginForm, LoginInfo, UserInfo, UserRole} from '@/lib/env/type-env'
import {useSessionStore} from "@/lib/env/useSessionStore.ts";
import {useGlobalRouter} from "@/store_pinia/useGlobalRouter.ts";
import {LocationQueryRaw} from "vue-router";
import {fetch} from "@common/inc/config_global.ts";
const log = T.getLogger("co-login", 'info')

/** 로그인 상태 조회 (수시로 호출)
 * - 로그인 상태(+세션 유효시간) 만 조회한다.
 * - 그래서 0.5초 이내 재요청은 캐시한다.
 */
export const apiGetLoginInfo = async (from = '') => {
  const sessionStore = useSessionStore()
  // 로그인 되어 있고 0.5초 이내 재요청은 재활용
  if (sessionStore.isLogin && new Date().getTime() - sessionStore.loginInfoLastGet < 500) {
    return sessionStore.loginInfo!
  }

  const res = await T.apiGetItem<LoginInfo>(fetch('/api/auth/emp_status'));
  return sessionStore.setLoginInfo(res)
}

/** env 조회 (주로 router beforeEnter 에 사용된다.)
 * - 로그인정보 + 사용자 환경 ( 가령 소속 그룹에 대한 전용 정보 ) 를 추가로 가져온다.
 * - 로그인이 안된 상태라도 url 이라던가 path 정보를 이용하여 소속 환경정보를 가져올 수 있다.
 * @param api_referer beforeEnter 에서 사용시에는 현재 주소가 아닌 내가 가려고 하는 주소에서의 ENV가 필요하기 때문에 반드시 입력해야 한다.<pre>
 *                    ex) api_referer = location.href?.replace(REGEXP_URL에서_path만_제외, "$1") + to.path
 * </pre> */
export const apiGetEnv = async (api_referer: string = ''): Promise<EnvType> => {
  const sessionStore = useSessionStore(); // 세션 스토어에 응답내용 등록
  // 로그인 되어 있고 0.5초 이내 재요청은 재활용
  if (sessionStore.env.login && new Date().getTime() - sessionStore.envLastGet < 500) {
    return sessionStore.env
  }
  
  const res = await T.apiGetItem<EnvType>(fetch("/api/auth/emp_env"), {api_referer});
  return sessionStore.setEnv(res)
}


export function useLogin() {

  const sessionStore = useSessionStore()
  
  

  /** 로그인 정보 */
  // const login_info = ref<LoginInfo>();

  // 로그인 실행
  const doLogin = async (loginform: LoginForm) => {
    try {
      const res = await T.apiPostItem<LoginInfo>(fetch("/api/auth/emp_login"), loginform)
      log.debug("ret = ", res)
      if (res.login === 'OTP') {
        T.noti_success("OTP 입력이 필요합니다.")
        return 'OTP';
      }
      sessionStore.setLoginInfo(res)
      T.noti_success("로그인 되었습니다.")
    } catch (e) {
      sessionStore.setNotLogin() // 로그인 실패시 로그인 실패로 설정
      throw e;
    }
    return true
  }

  // 로그아웃 실행
  const doLogout = async ({moveLoginPage = false, returnCurrentPath = false, noPassKey = false} = {}) => {
    
    const currentPath = location.pathname + location.search
    log.debug(`로그아웃 하는 주소 = ${currentPath}, moveLoginPage = ${moveLoginPage}`)
        
    await T.apiGet(fetch("/api/auth/emp_logout"), {currentPath})
    sessionStore.setNotLogin() // 로그인 실패시 로그인 실패로 설정
    T.noti_success("로그아웃 되었습니다.")
    if (moveLoginPage) {
      const query: LocationQueryRaw = {};
      if (returnCurrentPath) // 로그아웃 하지만 재 로그인시 현재 경로로 돌아오고 싶을때
        query.next_path = currentPath;
      if (noPassKey) // 로그인폼에 패쓰키 안띄우고 싶을때
        query.no_passkey = 1;
        // useGlobalRouter().push({path: "/login", query: {next_path: currentPath}}).then();
      // else
      //   useGlobalRouter().push({path: "/login"}).then();
      
      useGlobalRouter().push({path: "/login", query: query}).then();
    }
  }
  
  // 세션시간 갱신 (당장)
  const touchSessionNow = async () => {
    await apiGetLoginInfo()
  }
  
  // 세션시간 갱신 (10초 텀)
  const touchSession = _.throttle(
    touchSessionNow, // 세션 갱신 함수 호출
    30 * 1000,  // 30초 텀
    {
      leading: true,  // 첫 입력시 즉시 호출
      trailing: true   // 마지막 입력 후 호출
    }
  );

  // 관리자용 자동로그인 - 주로 관리자 > 사용자 관리에서 호출한다.
  const autoLogin = async (id) => {
    // TODO CHECK :: 이거 하긴 해야 하는데...
    const ret = await T.apiGet("/_login/" + id, {type: 'json'})
    // await getLoginStatus('[co-login.ts] autoLogin')
  }

  // // 로그인 상태확인
  // const getLoginStatus = async (from = '') =>
  //   // login_info.value = await T.apiGet("/v1/api/login/status") as LoginInfo
  //   login_info.value = await apiGetLoginInfo('co-login.ts getLoginStatus > '+from)

  /** 필수 로그인 */
  // const checkLogin = async (router?: VueRouter) => {
  //   const ret = await getLoginStatus('co-login.ts checkLogin');
  //   if (!ret.login) { // 로그인이 안되어있는 경우
  //     alert("로그인이 필요한 페이지 입니다.");
  //     const myRouter = router ?? getRouterByInst();
  //     log.debug("myRouter = ", myRouter)
  //     myRouter && await myRouter.push("/login");
  //     return false
  //   }
  //   return true
  // }

  return {
    /** 로그인 상태 */
    // login_info: readonly(login_info), // TODO CHECK :: 이렇게 해야 하는데 ide가 인식을 못한다;;;
    // login_info, // 로그인정보는 보는것만 가능하도록

    /** 기능 */
//    getLoginStatus, /*checkLogin,*/
    doLogin,
    doLogout,
    autoLogin,
    touchSession,
    touchSessionNow,

    // needOtpInput,
  }
  // const { login_info, checkLogin, getLoginStatus, doLogin, doLogout } = co_login(context)
}

export const getNoLoginUserInfo = () : UserInfo => {
  return {id: '', name: '', no: 0, grp: '', roles: [], level: 0, mfa_type: 'NONE'}
}


// env 에 기능 추가 - hasRole
export const useEnvExt = (Se?: EnvType): EnvTypeExt => {
  if (Se === undefined) { // 모든 route 에 대해서 env 를 주입하므로. 이것은 초기화용이다. 실제로는 사용되면 그건 버그다.
    Se = {
      login: false,
      user: getNoLoginUserInfo(),
      device: {mobile: false, ip: ''},
      group: {no: 0, id: '', name: ''}
    }
  }
  
  const hasRole = (rtype: UserRole) => Se.user?.roles?.includes(rtype) ?? false
  const hasRoleAny = (rtypes: UserRole[]) => Se.user?.roles?.some(role => rtypes.includes(role)) ?? false
  return {
    ...Se, hasRole, hasRoleAny,
    devMode: import.meta.env.DEV ?? false, // 개발모드 여부
  };
}



const REGEXP_URL에서_path만_제외 = new RegExp(/(https?:\/\/[^\/]+).*/)

// 라우터 beforeEnter 설정용 - envExt 주입한 - 필수 로그인 처리도 필요하면 한다.
export const setEnvFunction = (to, from, next) => {
  // if (store.state.accessToken !== '') {
  //   return next();
  // }
  // next('/login');
  log.isDebug && log.debug("beforeEnter() : API GET ENV 호출") // to.path = /mgr_academy/group/cust
  // T.trace(to)
  // 이대로 ENV 를 요청하면 가려고 하는 곳의 주소가 아니라. 기존(과거)의 URL로 ENV 요청이 들어가서 엉뚱한 결과가 나온다.
  // MPA 방식의 웹에서는 상관 없지만 SPA 개발모드는 routes 로 이동하기에 이 부분이 문제가 된다.
  // 그래서 임의로 to.path 를 이용하여 가려고 하는 주소를 미리 조합하여 ENV 요청시 파라미터 로 보낸다.
  const api_referer = location.href?.replace(REGEXP_URL에서_path만_제외,"$1") + to.path
  apiGetEnv(api_referer).then(v => {
    log.isDebug && log.debug("beforeEnter() : API GET ENV 응답", JSON.stringify(v, null, 2))
    to.meta.env = useEnvExt(v)
    if (v.login || to.meta.noAuth) { // 이 부분은 라우트 meta 에 로그인 no_login 같은걸 설정해서 비 로그인은 따로 해도 될듯
      next()
    } else {
      next({path: "/login", query: {next_path: to.fullPath}})
    }
  })
};


