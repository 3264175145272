import T from './index'
import {MapType} from "./tools_types";

export type QsValueType =
    'number[]'
  | 'string[]'
  | 'boolean'
  | 'number'
  | 'string'
  | 'remove'   // 제거
   // | 'comma_array' | 'comma_number_array'

export type QsTypes = Record<string, QsValueType>

export type QsArrayFormatType =
    'none'      // == ar=1&ar=2=ar=3        * 기본형태
  | 'bracket'   // == ar[]=1&ar[]=2=ar[]=3
  | 'separator' // == ar=1|2|3              * arrayFormatSeparator(기본값=|) 으로 join  
  | 'comma'     // == ar=1,2,3              * arrayFormat: 'separator', arrayFormatSeparator: ',' 하고 동일하다.

export interface QsOptions {
  arrayFormat?: QsArrayFormatType
  arrayFormatSeparator?: string
}

const applyDefaultOption = (opt: QsOptions): QsOptions => {

  const ret: QsOptions = {
    arrayFormat: 'none',
    arrayFormatSeparator: '|',
    ...opt
  }

  if (opt.arrayFormat === 'comma') {
    opt.arrayFormat = 'separator'
    opt.arrayFormatSeparator = ','
  }
  
  return ret
}


function addArrayValue(ar: string[], opt: QsOptions, key: string, value: any[] | any) {
  switch (opt.arrayFormat) {
    case 'none':      value.forEach(v => ar.push(key + '=' + encodeURIComponent(v))); break;
    case 'bracket':   value.forEach(v => ar.push(key + '[]=' + encodeURIComponent(v))); break;
    case 'separator': ar.push(key + '=' + value.map(v => encodeURIComponent(v)).join(opt.arrayFormatSeparator)); break;
  }
}

function parseArrayValue(data: Record<string, any>, key: string, value: string, opt: QsOptions, aType: QsValueType) {

  const convertFunc = (aType === 'number[]') ? parseFloat : (v: string) => v

  if (opt.arrayFormat === 'none') {
    appendParseValue(data, key, convertFunc(value), true);
    
  } else if (opt.arrayFormat === 'bracket') {
    appendParseValue(data, key.replace(/\[]$/, ""), convertFunc(value), true);
    
  } else if (opt.arrayFormat === 'separator') {
    value.split(opt.arrayFormatSeparator!!)
      .forEach(v => appendParseValue(data, key, convertFunc(v), true));
    
  }
}


function appendParseValue(result: Record<string, any>, key: string, value: string | number, forceArray = false) {
  if (result[key] !== undefined) { // 이미 키가 있다면
    if (Array.isArray(result[key])) { // 그게 배열이면
      result[key].push(value); // 뒤에 추가
    } else { // 아니라면 ( === 두번재 등록이라면 )
      result[key] = [result[key], value]; // 배열로 만든다.
    }
  } else {
    result[key] = forceArray ? [value] : value
  }
}

function qsStringify(data: Record<string, any>, qsType: QsTypes = {}, opt: QsOptions = {}): string {
  opt = applyDefaultOption(opt); // 기본값
  
  const ar: string[] = []

  for (const key in data) {
    const value = data[key];
    if (value === undefined || value === null) continue;
    
    // console.log(`key: ${key}, value: ${value}`)
    
    const defineType = qsType[key]
    if (defineType === undefined || defineType === null) { // 정의되지 않은 경우
      if (Array.isArray(value)) addArrayValue(ar, opt, key, value)
      else if (T.isNotBlank(value)) {
        ar.push(key + '=' + encodeURIComponent(value))
      }
    } else {
      switch (defineType) {
        case 'remove': break; //  제거
        case 'boolean':  ar.push(key + '=' + (value ? '1' : '0'));break;
        case 'number':   if (!isNaN(value)) { ar.push(key + '=' + value) } break;
        case 'string[]':
        case 'number[]': addArrayValue(ar, opt, key, value); break;
        default:
          if (T.isNotBlank(value)) {
            ar.push(key + '=' + encodeURIComponent(value));
          }
          break;
      }
    }
  }
  
  const ret = ar.join('&')
  // console.log(`ret: ${ret}`)
  return ret;
}


function qsParse(str: string, qsType: QsTypes = {}, opt: QsOptions = {}): Record<string, any> {
  opt = applyDefaultOption(opt); // 기본값

  if (!str || T.isBlank(str)) return {}

  let qs = str
  const questionPos = str.indexOf('?');
  if (questionPos > -1) { // ? 가 있으면 그 뒤에만 작업
    qs = str.substring(questionPos + 1);
  } else if (str.startsWith("http")) { // ? 도 없는데 http 로 시작하는 경우는 qs가 아에 없는 경우
    return {}
  }

  const result = {};
  const pairs = qs.split("&");

  pairs.forEach(p => {
    const pair = p.split("=");
    const key = pair[0];
    if (T.isEmpty(key)) return

    let value: any = decodeURIComponent(pair[1] || "");

    const defineType = qsType[key]
    if (defineType === undefined || defineType === null) { // 정의되지 않은 경우
      appendParseValue(result, key, value)
    } else {
      switch (defineType) { // 정의된 경우
        case 'remove': break; //  제거
        case 'boolean': result[key] = (value === '1' || value === 'true'); break; 
        case 'number':  result[key] = parseFloat(value); break;
        case 'string[]': parseArrayValue(result, key, value, opt, defineType); break;
        case 'number[]': parseArrayValue(result, key, value, opt, defineType); break;
      }
    }
  });

  return result
  
}

type InferType<T> = T extends number[] ? "number[]"
                  : T extends string[] ? "string[]"
                  : T extends boolean  ? "boolean"
                  : T extends number   ? "number"
                  : T extends string   ? "string"
                  : T extends null     ? "null"
                  : never;


const qsTypesScan = <
    T extends Record<string, any>,
    H extends Partial<Record<keyof T, QsValueType>> = {} // 힌트는 선택적
>(
    obj: T,
    hints: H = {} as H // 기본값으로 빈 객체 사용
// ): { [K in keyof T]: H[K] extends QsValueType ? H[K] : InferType<T[K]> | "string[]" | "string" } => {
): { [K in keyof T]: H[K] extends QsValueType ? H[K] : InferType<T[K]> } => {

  if (isProxy(obj)) obj = toRaw(obj)
  if (isRef(obj)) obj = unref<T>(obj)
  
  const result: any = {};
  for (const key in obj) {
    if (hints[key]) {
      result[key] = hints[key]; // 힌트가 있는 경우 힌트 값 사용
    } else {
      const value = obj[key];
      if (Array.isArray(value)) {
        if (value.length === 0) result[key] = "string[]";  // 기본값으로 string[] 사용
        else {
          if (value.every((v) => typeof v === "string")) result[key] = "string[]";
          else if (value.every((v) => typeof v === "number")) result[key] = "number[]";
        }
      }
      else if (typeof value === "number") result[key] = "number";
      // else if (typeof value === "string") result[key] = "string"; 
      // else if (value === null) result[key] = "null";
    }
  }
  return result;
};


class UrlHandler {

  public data: Record<string, any> = {};
  public prefix: string = '';
  
  private readonly qsType: QsTypes = {}
  private readonly opt: QsOptions = {}

  constructor(str: string, qsType: QsTypes = {}, opt: QsOptions = {}) {

    this.qsType = qsType
    this.opt = opt
    
    // console.log(`qsType -------`, JSON.stringify(qsType))

    let qs = str
    const questionPos = str.indexOf('?');
    if (questionPos > -1) { // ? 가 있으면 그 뒤에만 작업
      this.prefix = str.substring(0, questionPos);
      qs = str.substring(questionPos + 1);
    } else { // ? 도 없는데 http 로 시작하는 경우는 qs가 아에 없는 경우
      this.prefix = str
      qs = ''
    }

    // console.log(`1 this.str: `, str)
    // console.log(`2 this.prefix: `, this.prefix)

    this.data = T.qsParse(qs, qsType, opt)
  }

  put(key: string, value: any) {
    this.data[key] = value
    return this
  }
  
  putAll(mapObject: Record<string, any>) {
    for (const k in mapObject)
      this.data[k] = mapObject[k]
    return this
  }
  
  remove(...keys: string[]) {
    if (keys.length === 0) return this
    keys.forEach(k => delete this.data[k])
    return this
  }

  //       .replace(map)
  //       .remove([...removedKeys, ...dropKeys])

  // getPrefix() { return this.prefix }
  // getData() {return this.data}
  
  toString(): string {
    // console.log(`this.prefix: `, this.prefix)
    const qs = T.qsStringify(this.data, this.qsType, this.opt)
    return T.isBlank(qs) ? this.prefix : this.prefix + '?' + qs
  }

}





export default {
  qsStringify,
  qsParse,
  qsTypesScan,
  UrlHandler,
}
